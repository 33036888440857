// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en

@import '../../node_modules/ng-zorro-antd/ng-zorro-antd.less';
//@import '../../node_modules/ng-zorro-antd/ng-zorro-antd.aliyun.less';

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

//fonts
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=PT+Serif:wght@700&family=Poppins:wght@500&display=swap');
@font-face {
  font-family: 'inter-regular';
  src: url('../assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'inter-medium';
  src: url('../assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'inter-semibold';
  src: url('../assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@import './abstracts/variables.less';

.Loading {
  height: 100%;
  width: 100%;
  // background: rgb(40, 52, 67);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.gap {
  gap: 10px;
}
.gap-2 {
  gap: 20px;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.align-center {
  align-items: center;
}
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.space-evenly {
  justify-content: space-evenly;
}
.flex-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.flex-child {
  flex: 1; // This will make the child take up the remaining space
}

.flex-child-size-1 {
  flex: 1;
}

.flex-child-size-2 {
  flex: 2;
}

.flex-child-size-3 {
  flex: 3;
}

.flex-end-row {
  justify-content: flex-end;
}

.flex-end-column {
  margin-bottom: 0;
  margin-top: auto;
  justify-content: flex-end;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 auto;
}
.center-elements {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
button {
  cursor: pointer;
}

.ant-table {
  background: unset;

  .ant-table-title {
    background-color: @background-color-card;
    border: 0.5px solid #d9d9d9;
    border-radius: 2px 2px 0px 0px;
    // border-bottom: none;
  }
}
.ant-drawer-body {
  padding: 0;
}
.ant-drawer-header {
  padding: 24px;
  .ant-drawer-title {
    font-size: 20px;
    font-style: normal;

    font-weight: 600;
    line-height: 28px;
  }
}

.ant-avatar-string {
  position: unset;
}

.ant-tabs-tabpane {
  display: flex;
}
.ant-drawer-content {
  .ant-tabs-tabpane {
    flex-direction: column;
  }
}
.ant-tabs-nav-wrap {
  margin-left: 20px;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #545454;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #0f0f0f;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: #e6e6e6;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #e6e6e6;
}
::-webkit-scrollbar-track:active {
  background: #ababab;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.ant-form-item,
h2 {
  margin-bottom: 0;
}

.ant-steps-icon:first-child {
  display: none;
}

.ant-steps-icon:only-child {
  display: inline;
}

.xpw-badge {
  display: inline-flex;
  padding: 2px 10px;
  background-color: #0000000f;
  border-radius: 20px;
  height: auto;

  align-items: center;
  vertical-align: middle;
}

.ellipsis-text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.small-table-tooltip {
  max-width: 450px;
}
